h1 {
  font-size: 24px;
}

.text-center {
  color: #fff;
  font-weight: 600;
  font-size: 24px;
}

/* Wrapper for the color picker */
.color-picker-wrapper {
  position: relative;
}

/* Popover layout for the color picker */
.popover {
  position: absolute;
  z-index: 9999;
  top: 50px; /* Adjust based on your layout */
  left: 0;
}

/* Fullscreen cover to close the color picker when clicked outside */
.cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.custom-url-input {
  padding: 12px 15px;
  font-size: 16px;
  border: 2px solid #4a4e69; /* Adjust border color */
  border-radius: 8px;
  background-color: #f5f5f5; /* Light background for contrast */
  color: #333; /* Text color */
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  width: 100%;
}

.custom-url-input:focus {
  border-color: #fff; /* Darker border on focus */
  outline: none;
  box-shadow: 0 0 8px rgba(227, 227, 236, 0.3); /* Slight glow effect */
}

.custom-url-input::placeholder {
  color: #aaa; /* Placeholder text color */
}

@media (max-width: 768px) {
  .custom-url-input {
    font-size: 14px;
    padding: 10px 12px;
  }
}

/* Add to your main CSS file */
.input-file-label {
  color: #fff;
  font-weight: 600;
}

.input-file-wrapper {
  display: flex;
  margin-top: 1%;
  margin-left: 0.5%;
}
