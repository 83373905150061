.image-type-converter-container {
  text-align: center;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.image-type-converter-container h1 {
  color: #fff;
  font-size: x-large;
  margin-bottom: 24px;
}

.image-type-converter-heading {
  color: #000;
}

.image-type-converter-input-container {
  margin-top: 2%;
  margin-bottom: 20px;
}

.image-type-converter-input-container input[type="file"] {
  margin-right: 10px;
}

.image-type-converter-button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  width: 100%;
}

.image-type-converter-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #333;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid #333;
}

.image-type-converter-button:hover {
  background-color: #555;
}

.image-type-converter-button.selected {
  background-color: #007bff;
  border-color: #007bff;
}

.image-type-converter-results-container {
  padding: 1%;
}

.image-type-converter-results-table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  border: none !important;
}

.image-type-converter-results-table th,
.image-type-converter-results-table td {
  border: 1px solid #ddd;
  padding: 8px;
  border: none !important;
  text-align: center;
}

.image-type-converter-results-table th {
  background-color: #333 !important;
  color: #fff !important;
}

.image-type-converter-results-table td {
  background-color: transparent;
  text-align: center; /* Horizontally center-align text/content */
  vertical-align: middle;
}

.image-type-converter-image {
  width: 100px;
  height: 100px;
  object-fit: contain;
  background-color: #fff;
}

.image-type-converter-download-button {
  background-color: #fff;
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 30%;
  cursor: pointer;
}

.image-type-converter-download-icon {
  width: 30px;
  height: 30px;
}
