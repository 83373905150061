.image-resizer-card {
  background: transparent;
  text-align: center;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.image-resizer-card:hover {
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.image-resizer-card h1 {
  color: #fff;
  font-size: x-large;
  margin-bottom: 24px;
}

.card-bg {
  background: linear-gradient(260deg, #4a4e69, #22223b);
}

.card-bg p {
  color: #fff;
}

.dropzone {
  border: 2px dashed #000;
  border-radius: 8px;
  padding: 20px;
  cursor: pointer;
  background: linear-gradient(135deg, #4a4e69, #22223b);
  width: 100%; /* Full width of the column */
  height: 150px;
  margin: 0 auto; /* Center horizontally */
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropzone:hover {
  background-color: #3a3b4d;
}

.dropzone p {
  margin: 0;
  font-size: 1rem;
  color: #fff;
}

.card {
  border-radius: 0.375rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.btn-primary {
  background-color: #007bff;
  border: none;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.img-fluid {
  border-radius: 0.375rem;
}

.image-resizer-tabs .nav-link {
  background: #fff; /* Darker color for normal state */
  color: #000;
  border-radius: 8px;
  margin-right: 0.5rem;
  padding: 10px 20px;
  border: none;
}

.image-resizer-tabs .nav-link.active {
  color: #fff !important;
  background: linear-gradient(
    135deg,
    #4a4e69,
    #22223b
  ); /* Lighter gradient for active state */
  border: none;
}

.image-resizer-tabs .nav-link:hover {
  background: #333;
  color: #fff;
}

.range-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.range-container .text-center {
  margin-top: 0.5rem;
  font-size: 1rem;
}

/* Style for range input track */
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  background: linear-gradient(135deg, #4a4e69, #22223b); /* Apply gradient */
  border-radius: 5px;
  outline: none;
  opacity: 0.9;
  transition: opacity 0.2s;
}

/* Style for the thumb (handle) of the range slider */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: #4a4e69; /* Color of the thumb */
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* For Firefox */
input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background-color: #4a4e69; /* Color of the thumb */
  border-radius: 50%;
  cursor: pointer;
}

/* Hover effect for the thumb */
input[type="range"]::-webkit-slider-thumb:hover {
  background-color: #22223b; /* Darker on hover */
}

/* For a focused range input (when clicking) */
input[type="range"]:focus {
  outline: none;
}

input[type="range"]:hover {
  opacity: 1; /* Fully visible on hover */
}

.form-label {
  color: #fff;
}

.text-center {
  color: #fff;
  font-weight: 600;
}
