.converter-container {
  text-align: center;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.converter-container h1 {
  color: #fff;
  font-size: x-large;
  margin-bottom: 24px;
}

.file-input {
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
}

.output-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
}

.text-areas-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;
}

.csv-textarea,
.json-textarea {
  width: 100%;
  height: 300px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
}

.button-container {
  display: flex;
  gap: 10px;
}

.download-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #0056b3;
}

.custom-file-input {
  display: flex;
  background-color: #333;
  color: #fff;
  border: 2px solid #555;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 0%;
  margin-top: -0.4%;
}

.custom-file-input:hover {
  background-color: #444;
  border-color: #777;
}

.custom-file-input::file-selector-button {
  background-color: #666;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}

