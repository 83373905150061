.color-picker-container {
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.color-picker-container h1 {
  color: #fff;
  font-size: x-large;
  margin-bottom: 24px;
}

.color-picker-tabs {
  display: flex;
  border-bottom: 2px solid #ddd;
}

.color-picker-tab {
  padding: 10px;
  cursor: pointer;
  color: #fff;
}

.color-picker-tab.active {
  border-bottom: 2px solid #007bff;
  font-weight: bold;
}

.color-display {
  width: 100%;
  height: 100px;
  border-radius: 8px;
  background-color: #fff;
  border: 2px solid #ddd;
}

.color-format-values {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
}

.color-value-display {
  flex: 1;
}

.input {
  border: none;
  width: 100%;
  background: transparent;
  outline: none;
  color: #000;
}

.copy-icon {
  background-color: #007bff;
  border-radius: 8px;
  padding: 5px;
}

.copy-icon:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.label {
  color: #fff;
  font-weight: 700;
  text-align: left;
}

.color-edit-settings {
  margin-top: 20px;
}

.color-edit-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.color-edit-row span {
  width: 30px;
}

.color-edit-row input[type="range"],
.color-edit-row input[type="number"] {
  margin-left: 10px;
  width: 100%;
}

@media (max-width: 768px) {
  .color-edit-row {
    flex-direction: column;
    align-items: flex-start;
  }
  .color-edit-row input[type="number"] {
    width: 100%;
  }
  .color-picker-tab {
    font-size: 14px;
  }
}

.input {
  border: none;
  width: 100%;
  background: transparent;
  outline: none;
  color: #000;
  font-size: 16px;
}

.copy-icon {
  background-color: #007bff;
  border-radius: 8px;
  padding: 5px;
  margin-left: 10px;
  cursor: pointer;
}

.color-edit-row input[type="number"] {
  width: 60px;
}

.rcp-root {
  width: 100%;
}

.rcp-saturation {
  height: 300px !important; /* Adjust to your desired height */
}
