.og-tag-checker {
  text-align: center;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.og-tag-checker h1 {
  text-align: center;
  color: #fff;
  font-size: x-large;
}

.form-group1 {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.error {
  color: red;
  font-weight: 700;
  text-align: center;
  background-color: #f4f4f4;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

table,
th,
td {
  border: 1px solid #ddd;
}

th,
td {
  padding: 10px;
  text-align: left;
  color: #fff;
}

th {
  background-color: #f4f4f4;
  color: #333;
}

td {
  background-color: transparent !important;
  color: #fff !important;
}

@media (max-width: 768px) {
  button {
    width: 100%;
    font-size: 14px;
  }

  input[type="text"] {
    font-size: 14px;
  }

  table {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 24px;
  }

  input[type="text"] {
    font-size: 12px;
  }

  button {
    font-size: 12px;
  }

  table {
    font-size: 12px;
  }
}

p {
  padding: 1%;
}

.og-tag-checker .row {
  margin-top: 20px;
}

.gap-row {
  display: flex;
  flex-wrap: wrap;
}

/* Loader and Progress Bar Styles */
.loader-container {
  text-align: center;
  margin-top: 20px;
}

.spinner {
  border: 8px solid rgb(255, 255, 255, 0.7);
  border-left-color: #007bff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Collapsible Section Styles */
.collapse {
  margin-top: 20px;
}

.collapse .card {
  background-color: #333;
  color: #fff;
}

.collapse .card-body {
  color: #fff;
}

.collapse-toggle {
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
}
