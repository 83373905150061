/* Header.css or relevant CSS file */
.custom-dropdown-menu {
  /* background: linear-gradient(135deg, #2e3b4e, #1b2735) !important; */
  border: none;
  box-shadow: none;
}

.custom-dropdown-menu .dropdown-item {
  color: #fff;
}

.custom-dropdown-menu .dropdown-item:hover {
  background: rgba(255, 255, 255, 0.2);
}

.collapse {
  margin-top: 0% !important;
}
