html,
body {
  /* height: 100%; */
  margin: 0;
  background: linear-gradient(135deg, #2e3b4e, #1b2735);
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}

footer {
  margin-top: auto; /* Pushes the footer to the bottom */
}

.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin: 20px 0;
  overflow: hidden;
  position: relative;
  height: 20px;
}

.progress-bar-fill {
  width: 100%;
  height: 100%;
  background-color: #45a247;
  animation: progress 1.5s linear;
}

@keyframes progress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.custom-file-input {
  background-color: #333;
  color: #fff;
  border: 2px solid #555;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  margin: 3%;
  width: 93%;
}

.custom-file-input:hover {
  background-color: #444;
  border-color: #777;
}

.custom-file-input::file-selector-button {
  background-color: #666;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}

.custom-file-input::file-selector-button:hover {
  background-color: #888;
}

.error-message {
  color: red; /* Red color for error */
  background-color: #ffe6e6; /* Light red background */
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  border: 1px solid #ff4d4d;
  font-weight: 700;
}

/* Loader and Progress Bar Styles */
.loader-container {
  text-align: center;
  margin-top: 20px;
}

.spinner {
  border: 8px solid rgb(255, 255, 255, 0.7);
  border-left-color: #007bff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
