.compressor-container {
  text-align: center;
  margin: 20px auto;
  max-width: 800px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #fff;
}

.input-container {
  margin-bottom: 20px;
}

input[type="file"] {
  margin-right: 10px;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.image-type-button {
  padding: 7px 6px;
  font-size: 16px;
  color: #333;
  background-color: #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid #ccc;
}

.image-type-button:hover {
  background-color: #ccc;
}

.image-type-button.selected {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.image-preview {
  position: relative;
  margin-top: 20px;
  width: 100%;
}

.image-preview img {
  height: 100px;
  object-fit: cover;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.preview-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  text-align: left;
}

p {
  font-size: 16px;
  color: #fff;
  text-align: center;
}

button {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #45a247;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .preview-container {
    display: block;
  }

  .image-preview {
    margin-bottom: 20px;
  }

  .image-preview img {
    height: auto;
    width: 100%;
  }

  .button-group {
    flex-direction: column;
  }
}

.progress-circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  padding: 3%;
}

.progress-circle {
  width: 110px;
  height: 110px;
  position: relative;
}

.progress-circle svg {
  transform: rotate(-90deg);
}

.progress-circle circle {
  fill: none;
  stroke-width: 10;
  stroke-linecap: round;
}

.progress-circle .background-circle {
  stroke: #e0e0e0;
}

.progress-circle .progress-circle-fill {
  stroke: green;
  transition: stroke-dashoffset 0.5s ease;
}

.progress-circle-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: 800;
  color: lightgreen;
}
